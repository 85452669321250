<template>
  <div>
    <a-table
      :dataSource="table_data"
      :columns="table_columns"
      :pagination="pagination_props"
      @change="changeCurrentPage"
      :loading="loading_data"
    >
      <!-- Modal de filtro de input -->
      <template #filterDropdown="{ column, confirm, clearFilters }">
        <div style="padding: 8px">
          <a-form
            name="form"
            layout="vertical"
            ref="formRef"
            :model="filter_values"
            :rules="rules"
          >
            <a-form-item has-feedback :name="column.dataIndex">
              <a-input
                v-model:value="filter_values[column.key]"
                @keyup="formatRuts"
                :maxlength="
                  column.dataIndex == 'employeeRut' ||
                  column.dataIndex == 'companyRut'
                    ? 12
                    : 40
                "
                :placeholder="`Buscar por ${column.title}`"
                @pressEnter="
                  handleSearch(
                    column.dataIndex,
                    filter_values[column.key],
                    confirm
                  )
                "
                style="width: 188px; margin-bottom: 8px; display: block"
              />
            </a-form-item>

            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  filter_values[column.key],
                  confirm
                )
              "
            >
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </a-form>
        </div>
      </template>

      <!-- Modal de filtro de input -->
      <template #filterCompanies="{ column, confirm, clearFilters }">
        <div style="padding: 8px; width: 300px">
          <a-select
            v-model:value="selected_companies"
            :options="companies"
            mode="multiple"
            show-search
            :filterOption="true"
            optionFilterProp="label"
            placeholder="Buscar por Razon social"
            style="width: 100%"
            size="middle"
          >
            <template #dropdownRender="{ menuNode: menu }">
              <v-nodes :vnodes="menu" />
              <div
                style="
                  border-top: 1px solid #f0f0f0;
                  display: flex;
                  justify-content: space-between;
                "
                class="p-2"
                @mousedown="(e) => e.preventDefault()"
              >
                <a-button
                  type="primary"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="
                    handleSearch(
                      column.dataIndex,
                      selected_companies.join(','),
                      confirm
                    )
                  "
                >
                  Buscar
                </a-button>

                <a-button
                  size="small"
                  style="width: 90px"
                  @click="handleColumnReset(column.dataIndex, clearFilters)"
                >
                  Limpiar
                </a-button>
              </div>
            </template>
          </a-select>
          <div
            class="mt-4 p-2"
            style="
              border-top: 1px solid #f0f0f0;
              display: flex;
              justify-content: space-between;
            "
          >
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  selected_companies.join(','),
                  confirm
                )
              "
            >
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </div>
      </template>

      <!-- Filtros para estados -->

      <template #filterStates="{ confirm, column, clearFilters }">
        <div style="padding: 8px; width: 300px">
          <a-checkbox-group v-model:value="states_selection">
            <a-row>
              <a-col
                :span="24"
                v-for="(state, index) in states_options"
                :key="index"
              >
                <a-checkbox :value="state.value">{{ state.label }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>

          <a-checkbox
            v-if="ticket_type == 'Alta'"
            v-model:checked="requested_file"
            >En resolicitud</a-checkbox
          >

          <div
            class="mt-4 p-2"
            style="
              border-top: 1px solid #f0f0f0;
              display: flex;
              justify-content: space-between;
            "
          >
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  states_selection.join(','),
                  confirm
                )
              "
            >
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </div>
      </template>

      <!-- Tag de los estados -->
      <template #tag="{ text: tag }">
        <tag v-if="tag == 'requested'" color="warning"> EN RESOLICITUD </tag>
        <tag v-else :stateId="tag" />
      </template>

      <!-- Icono de filtrado de lupa -->
      <template #filterIcon="{ column }">
        <search-outlined
          :style="{
            color:
              search_value[column.dataIndex] === undefined ||
              search_value[column.dataIndex] === ''
                ? undefined
                : '#2521E5'
          }"
        />
      </template>

      <!-- Icono de filtrado para seleccion por checkbox -->
      <template #filterIconState="{ column }">
        <filter-outlined
          :style="{
            color: iconStateFiltered(column.dataIndex) ? undefined : '#2521E5'
          }"
        />
        <!-- search_value[column.dataIndex] === undefined || search_value[column.dataIndex] === '' -->
      </template>

      <!-- Tag de los estados -->
      <template #details="{ text }">
        <a-tooltip :color="'#05045D'">
          <template #title>Ver más</template>
          <router-link
            :to="{
              name:
                ticket_type == 'Alta'
                  ? 'AltasDetalle'
                  : ticket_type == 'Baja'
                  ? 'BajaDetail'
                  : ticket_type == 'Modificacion'
                  ? 'DetalleModificaciones'
                  : ticket_type == 'Bajas de cargas'
                  ? 'BajaCargasDetalle'
                  : 'AltaCargasDetalle',
              params: { id: text }
            }"
          >
            <eye-outlined />
          </router-link>
        </a-tooltip>

        <a-tooltip
          :color="'#05045D'"
          @click="openModal(text)"
          class="ml-3 request_icon"
          v-if="
            table_data.find((x) => x.id == text).state == 1 &&
            ticket_type == 'Alta'
          "
        >
          <template #title>Reenviar formulario</template>
          <mail-outlined />
        </a-tooltip>
      </template>
    </a-table>

    <!-- Modal de resolicitud -->
    <a-modal
      v-model:visible="request_modal_visible"
      title="Reenviar Formulario Incorporación"
      :closable="!loading_modal"
      :maskClosable="!loading_modal"
    >
      <b>Correo eléctronico actual: {{ found_mail }}</b>

      <a-form
        name="form"
        layout="vertical"
        ref="form_ref"
        :model="form_state"
        :rules="rules"
      >
        <a-form-item
          class="mt-3"
          name="email"
          label="Actualizar correo electrónico del colaborador (opcional)"
        >
          <a-input
            v-model:value="form_state.email"
            placeholder="Ingresa nuevo correo electrónico"
            :maxlength="35"
          />
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button key="back" @click="handleCancel" :disabled="loading_modal"
          >Cancelar</a-button
        >
        <a-button key="submit" @click="requestFile" :loading="loading_modal"
          >Reenviar</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { message } from 'ant-design-vue';

import {
  SearchOutlined,
  FilterOutlined,
  EyeOutlined,
  MailOutlined
} from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import {
  FETCH_TABLE_TICKETS,
  GETTER_TICKET_TABLE_ROWS,
  GETTER_TICKET_TABLE_TOTAL_PAGES,
  GETTER_TICKET_TABLE_ITEMS_PER_PAGE,
  GETTER_TICKET_TABLE_CURRENT_PAGE,
  GET_ALL_COMPANIES,
  FETCH_ALL_COMPANIES,
  TICKET_RESEND_FORM_INCORPORATION
} from '../../store/types';
import rutHelper from '../../utils/Ruts';
import tag from '../tags.vue';

export default {
  name: 'TicketsTable',
  components: {
    'search-outlined': SearchOutlined,
    'filter-outlined': FilterOutlined,
    'eye-outlined': EyeOutlined,
    'mail-outlined': MailOutlined,
    tag,
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    }
  },
  props: {
    type: String,
    dates: Object
  },
  setup(props) {
    // Tipo de ticket
    const ticket_type = computed(() => {
      switch (route.name) {
        case 'Altas':
          return 'Alta';
        case 'AltasCargas':
          return 'Altas de cargas';
        case 'Bajas':
          return 'Baja';
        case 'BajasCargas':
          return 'Bajas de cargas';
        case 'Modificaciones':
          return 'Modificacion';
        case 'Transfers':
          return 'Traspaso';
        default:
          break;
      }
      return '';
    });

    /*
    Tabla para la carga de resultados segun filtro que hace el usuario, paginacion y filtro por fechas
    TODO: (1)[X] Cargar vue routes para las operaciones de las rutas
    TODO: (2)[X] Cualquier cambio que se haga a la url tener una manera de estar atentos a ello
    TODO: (3)[X] Cuando se haga un filtro, paginacion nueva o filtro de fecha, redireccionar la pagina con el nuevo url
    TODO: (4)[X] Al momento de que se cargue la pagina, obtener los valores de la url para ponerlos en los filtros asi para tener un control de lo que se busco
    TODO: (5)[X] Filtros de las columnas con las rutas
    */

    // * VUEX
    const store = useStore();

    // * Vue router ### (1) ###
    const route = useRoute();
    const router = useRouter();
    const formRef = ref();
    const validError = ref();

    // * Estar atento a cualquier cambio de la url ### (2) ###
    const loading_data = ref(false); // efecto de carga de la tabla cuando encuentre la data
    watchEffect(async (onCleanUp) => {
      try {
        if (!ticket_type.value) return;
        loading_data.value = true;
        const ac = new AbortController();
        const signal = ac.signal;
        onCleanUp(() => ac.abort());
        const queryParams = route.fullPath.split('?')[1] || '';
        await store.dispatch(FETCH_TABLE_TICKETS, {
          payload: `type=${ticket_type.value}&${queryParams}`,
          signal
        });
        loading_data.value = false;
      } catch (error) {
        loading_data.value = false;
        console.log(error);
      }
    });

    // * Redireccion al momento de hacer un filtro ### (3) ###
    const filter_values = ref({}); // Objeto que guarda los valores de los filtros
    const search_value = ref({}); // Objeto que guarda los valores de los cuales se hace la busqueda por filtro

    const deleteParamForRedirection = (key) => {
      delete search_value.value[key];
    };

    const addParamForRedirection = (key, param) => {
      if (key == 'companyRut' || key == 'employeeRut') {
        search_value.value[key] = rutHelper.normalizeRut(param);
      } else {
        search_value.value[key] = param;
      }

      if (key != 'page') {
        search_value.value['page'] = 1;
      }
    };

    const filterRedirectionNewParam = () => {
      formRef.value
        .validate()
        .then(() => {
          router.replace({
            name: route.name,
            query: search_value.value
          });
        })
        .catch((error) => {
          console.log('error', error);
          validError.value = error;
        });
    };

    const formatRuts = () => {
      if (filter_values.value['employeeRut']) {
        filter_values.value['employeeRut'] = rutHelper.formatRut(
          filter_values.value['employeeRut']
        );
      }
      if (filter_values.value['companyRut']) {
        filter_values.value['companyRut'] = rutHelper.formatRut(
          filter_values.value['companyRut']
        );
      }
    };

    // Redireccion reseteando o sacando un parametro
    const filterRedirectionResetColumn = (key) => {
      delete search_value.value[key];

      router.replace({
        name: route.name,
        query: search_value.value
      });
    };

    // * Filtros de las columnas con las rutas cambiantes ### (5) ###
    // Tabla
    const table_columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Holding',
        dataIndex: 'holding',
        key: 'holding',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Subholding',
        dataIndex: 'subHolding',
        key: 'subHolding',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Razón social',
        dataIndex: 'companyId',
        key: 'companyId',
        slots: {
          filterDropdown: 'filterCompanies',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'RUT empresa',
        dataIndex: 'companyRut',
        key: 'companyRut',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Nombre',
        dataIndex: 'employeeName',
        key: 'employeeName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'RUT empleado',
        dataIndex: 'employeeRut',
        key: 'employeeRut',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Estado',
        dataIndex: 'state',
        key: 'state',
        slots: {
          customRender: 'tag',
          filterDropdown: 'filterStates',
          filterIcon: 'filterIconState'
        }
      },
      {
        title: 'Opciones',
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'details'
        }
      }
    ];

    const table_data = computed(() => {
      return store.getters[GETTER_TICKET_TABLE_ROWS].map((obj) => {
        return {
          id: obj.id,
          key: obj.id,
          holding: obj.holding,
          companyId: obj.company.name,
          companyRut: rutHelper.formatRut(obj.company.rut),
          email: obj.employee.email,
          subHolding: obj.subHolding,
          employeeName: obj.employee.name,
          employeeRut: rutHelper.formatRut(obj.employee.rut),
          state: obj.isRequesting == false ? obj.state.id : 'requested'
        };
      });
    });

    const total = computed(() => {
      return store.getters[GETTER_TICKET_TABLE_TOTAL_PAGES];
    });

    // Items por pagina
    const items_per_page = computed(() => {
      return store.getters[GETTER_TICKET_TABLE_ITEMS_PER_PAGE];
    });

    // Pagina en la que nos encontramos
    const current_page = computed(() => {
      return store.getters[GETTER_TICKET_TABLE_CURRENT_PAGE];
    });

    const pagination_props = reactive({
      total,
      pageSize: items_per_page,
      current: current_page
    });

    const changeCurrentPage = (page) => {
      addParamForRedirection('page', page.current);
      filterRedirectionNewParam();
    };

    // Seleccion de estados
    const states_selection = ref([]);
    const states_options = ref([
      {
        label: 'Creado',
        value: 1
      },
      {
        label: 'En revisión corredora',
        value: 7
      },
      {
        label: 'En revisión aseguradora',
        value: 8
      },
      {
        label: 'Ejecutado',
        value: 3
      },
      {
        label: 'Cancelado',
        value: 4
      }
    ]);

    // Busqueda por columna
    const handleSearch = (column, value, confirm) => {
      formRef.value.validate().then(() => {
        if (column == 'state') {
          if (requested_file.value == true) {
            addParamForRedirection('isRequesting', true);
          } else {
            deleteParamForRedirection('isRequesting');
          }
          if (value === '' || value === undefined) {
            deleteParamForRedirection('state');
          }
        }

        if (value != '' && value != undefined) {
          addParamForRedirection(column, value);
        }

        filterRedirectionNewParam();
        confirm();
      });

      if (value == undefined || value == '') {
        formRef.value.clearValidate();
      }
    };

    const iconStateFiltered = (column) => {
      // console.log('search_value.value[column] === undefined || requested_file.value === false',search_value.value[column] === undefined || requested_file.value === false);
      return (
        search_value.value[column] === undefined &&
        requested_file.value === false
      );
    };

    // Limpiar columna
    const handleColumnReset = (column, resetConfirm) => {
      filterRedirectionResetColumn(column);
      resetConfirm();
      delete filter_values.value[column];
      if (column == 'employeeRut' || column == 'companyRut') {
        formRef.value.resetFields();
        formRef.value.clearValidate();
      }

      if (column === 'state') {
        states_selection.value = [];
        requested_file.value = false;
        filterRedirectionResetColumn('isRequesting');
      }

      if (column === 'companyId') {
        selected_companies.value = [];
      }
    };

    // Busqueda por estado
    const stateTicketSearchFilter = (states, confirm) => {
      addParamForRedirection('state', states.join(','));
      filterRedirectionNewParam();
      confirm();
    };

    // * Selector por compania
    /*
    Como el selector de compania tiene que ver en conjunto a la parte del dashboard donde el usuario tiene que selecccionar que
    companias van a aparecer filtradas antes de entrar, va a tener una logica dintinta a las demas selecciones y va a ser de este estilo
    */
    const getCompaniesList = async () => {
      try {
        store.dispatch(FETCH_ALL_COMPANIES);
      } catch (error) {
        console.log(error);
      }
    };

    const selected_companies = ref([]);
    const companies = computed(() => {
      return store.getters[GET_ALL_COMPANIES].map((obj) => {
        return {
          value: obj.id,
          label: obj.businessName
        };
      });
    });

    // * Filtro de resolcitud
    const requested_file = ref(false);

    // * Filtro de fecha desde afuera
    watch(
      () => props.dates,
      (new_val) => {
        if (new_val[0] != undefined && new_val[1] != undefined) {
          addParamForRedirection('startDate', new_val[0]);
          addParamForRedirection('endDate', new_val[1]);
        } else {
          deleteParamForRedirection('startDate');
          deleteParamForRedirection('endDate');
        }

        filterRedirectionNewParam();
      }
    );

    watch(filter_values.value, (value) => {
      if (value.employeeRut == '') {
        formRef.value.clearValidate();
      }
      if (value.companyRut == '') {
        formRef.value.clearValidate();
      }
    });

    // * Modal de resolicitud
    const form_ref = ref();
    const request_modal_visible = ref(false);
    const found_mail = ref('');
    const ticket_id_found = ref();
    const loading_modal = ref(false);
    const openModal = (id) => {
      ticket_id_found.value = id;
      request_modal_visible.value = true;
      found_mail.value = table_data.value.find((x) => x.id == id).email;
    };

    const form_state = reactive({
      email: ''
    });

    const validateEmail = (mail) => {
      return String(mail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const checkEmail = async (_, value) => {
      if (!value || value == '') {
        return Promise.resolve();
      }
      if (validateEmail(value)) {
        return Promise.resolve();
      }
      return Promise.reject('Correo inválido');
    };
    const checkRut = async (_, value) => {
      if (!value) {
        return;
      }
      if (!rutHelper.validateRut(value)) {
        return Promise.reject('RUT no válido');
      }
      return Promise.resolve();
    };

    const rules = {
      email: [
        {
          required: true,
          validator: checkEmail,
          trigger: 'change'
        }
      ],
      employeeRut: [
        {
          required: false,
          validator: checkRut,
          trigger: 'change'
        }
      ],
      companyRut: [
        {
          required: false,
          validator: checkRut,
          trigger: 'change'
        }
      ]
    };

    const handleCancel = () => {
      request_modal_visible.value = false;
    };

    const requestFile = () => {
      loading_modal.value = true;
      form_ref.value
        .validate()
        .then((resp) => {
          const payload = {};
          if (resp.email != '') {
            payload['email'] = resp.email;
          }

          store
            .dispatch(TICKET_RESEND_FORM_INCORPORATION, {
              id: ticket_id_found.value,
              payload
            })
            .then(() => {
              loading_modal.value = false;
              message.success('Resolicitado');
              request_modal_visible.value = false;
            })
            .catch(() => {
              loading_modal.value = false;
              message.error('Ocurrio un error, vuelve a intentarlo mas tarde');
              request_modal_visible.value = false;
            });
        })
        .catch(() => {
          loading_modal.value = false;
          request_modal_visible.value = false;
        });
    };

    // * Recarga de pagina, obtencion de los valores de la url a filtros ### (4) ###
    onMounted(() => {
      // Voy a obtener la lista de companias para el selector mismo
      getCompaniesList();

      // Al momento de cargar la pagina, voy a ver que parametros vienen, con eso voy a dejar los valores de cada uno de los filtros realizados ahi
      const keys = Object.keys(route.query);

      keys.forEach((element) => {
        filter_values.value[element] = route.query[element]; // Le paso valor al los inputs para que muestre que es lo que busco anteriormente
        search_value.value[element] = route.query[element]; // Le paso valor al objeto de la busqueda en caso de hacer otra consulta

        // en caso de que el filtro que venga sea 'State', voy a dejar selecciadas las casillas checkbox
        if (element === 'state') {
          states_selection.value.push(
            ...route.query[element].split(',').map((e) => parseInt(e))
          );
        }

        // en caso de que el filtro que venga sea 'companyId', voy a dejar selecciadas las casillas checkbox
        if (element === 'companyId') {
          selected_companies.value.push(
            ...route.query[element].split(',').map((e) => parseInt(e))
          );
        }

        // En caso de que este en resolicitud
        if (element === 'isRequesting') {
          requested_file.value = true;
        }
      });
    });

    return {
      filter_values,
      search_value,
      table_columns,
      table_data,
      handleColumnReset,
      handleSearch,
      filterRedirectionNewParam,
      pagination_props,
      changeCurrentPage,
      states_selection,
      states_options,
      stateTicketSearchFilter,
      loading_data,
      companies,
      selected_companies,
      ticket_type,
      requested_file,
      request_modal_visible,
      openModal,
      found_mail,
      form_ref,
      form_state,
      rules,
      handleCancel,
      requestFile,
      loading_modal,
      rutHelper,
      formatRuts,
      formRef,
      validError,
      iconStateFiltered
    };
  }
};
</script>

<style scoped>
.request_icon {
  color: #2521e5;
}
</style>
